<template>
  <div id="productRecallAccount">
    <el-dialog
      :title="productRecallAccountFormTitle"
      width="680px"
      :visible.sync="productRecallAccountDialogVisible"
      :close-on-click-modal="false"
      @close="productRecallAccountDialogClose"
    >
      <el-form
        ref="productRecallAccountFormRef"
        :model="productRecallAccountForm"
        :rules="productRecallAccountFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="productRecallAccountForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品批号" prop="batchNo">
              <el-input v-model="productRecallAccountForm.batchNo" placeholder="请输入产品批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customerName">
              <el-input v-model="productRecallAccountForm.customerName" placeholder="请输入客户名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货数量" prop="shipQuantity">
              <el-input v-model="productRecallAccountForm.shipQuantity" placeholder="请输入发货数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已使用数量" prop="quantityUsed">
              <el-input v-model="productRecallAccountForm.quantityUsed" placeholder="请输入已使用数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="召回数量" prop="recallQuantity">
              <el-input v-model="productRecallAccountForm.recallQuantity" placeholder="请输入召回数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="召回日期" prop="recallDate">
              <el-date-picker v-model="productRecallAccountForm.recallDate" placeholder="请选择召回日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="记录人" prop="recorder">
              <el-input v-model="productRecallAccountForm.recorder" placeholder="请输入记录人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="productRecallAccountForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="productRecallAccountDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productRecallAccountFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="productRecallAccountPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="产品批号" />
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="shipQuantity" label="发货数量" />
      <el-table-column prop="quantityUsed" label="已使用数量" />
      <el-table-column prop="recallQuantity" label="召回数量" />
      <el-table-column label="召回日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recallDate">{{ scope.row.recallDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recorder" label="记录人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="productRecallAccountPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProductRecallAccount, deleteProductRecallAccount, updateProductRecallAccount, selectProductRecallAccountInfo, selectProductRecallAccountList } from '@/api/universal/productRecallAccount'

export default {
  data () {
    return {
      productRecallAccountDialogVisible: false,
      productRecallAccountFormTitle: '',
      productRecallAccountForm: {
        id: '',
        productName: '',
        batchNo: '',
        customerName: '',
        shipQuantity: '',
        quantityUsed: '',
        recallQuantity: '',
        recallDate: '',
        recorder: '',
        remarks: ''
      },
      productRecallAccountFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      productRecallAccountPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectProductRecallAccountList(this.searchForm).then(res => {
      this.productRecallAccountPage = res
    })
  },
  methods: {
    productRecallAccountDialogClose () {
      this.$refs.productRecallAccountFormRef.resetFields()
    },
    productRecallAccountFormSubmit () {
      if (this.productRecallAccountFormTitle === '产品召回台帐详情') {
        this.productRecallAccountDialogVisible = false
        return
      }
      this.$refs.productRecallAccountFormRef.validate(async valid => {
        if (valid) {
          if (this.productRecallAccountFormTitle === '新增产品召回台帐') {
            await addProductRecallAccount(this.productRecallAccountForm)
          } else if (this.productRecallAccountFormTitle === '修改产品召回台帐') {
            await updateProductRecallAccount(this.productRecallAccountForm)
          }
          this.productRecallAccountPage = await selectProductRecallAccountList(this.searchForm)
          this.productRecallAccountDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.productRecallAccountFormTitle = '新增产品召回台帐'
      this.productRecallAccountDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProductRecallAccount(row.id)
        if (this.productRecallAccountPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.productRecallAccountPage = await selectProductRecallAccountList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.productRecallAccountFormTitle = '修改产品召回台帐'
      this.productRecallAccountDialogVisible = true
      this.selectProductRecallAccountInfoById(row.id)
    },
    handleInfo (index, row) {
      this.productRecallAccountFormTitle = '产品召回台帐详情'
      this.productRecallAccountDialogVisible = true
      this.selectProductRecallAccountInfoById(row.id)
    },
    selectProductRecallAccountInfoById (id) {
      selectProductRecallAccountInfo(id).then(res => {
        this.productRecallAccountForm.id = res.id
        this.productRecallAccountForm.productName = res.productName
        this.productRecallAccountForm.batchNo = res.batchNo
        this.productRecallAccountForm.customerName = res.customerName
        this.productRecallAccountForm.shipQuantity = res.shipQuantity
        this.productRecallAccountForm.quantityUsed = res.quantityUsed
        this.productRecallAccountForm.recallQuantity = res.recallQuantity
        this.productRecallAccountForm.recallDate = res.recallDate
        this.productRecallAccountForm.recorder = res.recorder
        this.productRecallAccountForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProductRecallAccountList(this.searchForm).then(res => {
        this.productRecallAccountPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProductRecallAccountList(this.searchForm).then(res => {
        this.productRecallAccountPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProductRecallAccountList(this.searchForm).then(res => {
        this.productRecallAccountPage = res
      })
    }
  }
}
</script>

<style>
</style>
