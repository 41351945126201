import axios from '@/common/axios'
import qs from 'qs'

export function addProductRecallAccount (data) {
  return axios({
    method: 'post',
    url: '/universal/recallAccount/add',
    data: qs.stringify(data)
  })
}

export function deleteProductRecallAccount (id) {
  return axios({
    method: 'delete',
    url: '/universal/recallAccount/delete/' + id
  })
}

export function updateProductRecallAccount (data) {
  return axios({
    method: 'put',
    url: '/universal/recallAccount/update',
    data: qs.stringify(data)
  })
}

export function selectProductRecallAccountInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/recallAccount/info/' + id
  })
}

export function selectProductRecallAccountList (query) {
  return axios({
    method: 'get',
    url: '/universal/recallAccount/list',
    params: query
  })
}
